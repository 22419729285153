label[for="code-input"] {
  display: block;
  padding-bottom: 10px;
}

label[for="code-input"] span {
  opacity: 0.5;
}

@keyframes blink-caret {
  50% {
    background: transparent;
  }
}

@keyframes shake {
  25% {
    transform: translateX(10px);
  }
  75% {
    transform: translateX(-10px);
  }
}

@keyframes pulse-border {
  50% {
    border-color: var(--segment-color);
    box-shadow: rgb(255, 255, 255) 0px 0px 0px 0px,
      var(--segment-color) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px;
  }
}

.recovery-code-input.-state-error {
  animation: shake 0.15s ease-in-out 0s 2;
}

.recovery-code-input input {
  caret-color: transparent;
}

.recovery-code-input input::selection {
  background-color: transparent;
}

.recovery-code-input .segment {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  border: 2px solid rgb(209, 213, 219);
  border-radius: 0.375rem;
  display: flex;
  --segment-color: #fc90bd;
}

.recovery-code-input.-focused .segment[data-state="selected"],
.recovery-code-input.-focused .segment[data-state="cursor"],
.recovery-code-input.-state-success .segment,
.recovery-code-input.-state-error .segment {
  outline: 2px solid transparent;
  outline-offset: 2px;
  border-color: var(--segment-color);
  box-shadow: rgb(255, 255, 255) 0px 0px 0px 0px,
    var(--segment-color) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px;
}

.recovery-code-input.-state-loading .segment {
  animation: pulse-border 1s ease-in-out 0s infinite;
}

.recovery-code-input.-state-error .segment {
  --segment-color: #bb0000;
}

.recovery-code-input.-state-success .segment {
  --segment-color: #28bb74;
}

.recovery-code-input.-focused .segment[data-state="selected"] div,
.recovery-code-input.-state-success .segment div,
.recovery-code-input.-state-error .segment div {
  flex: 1;
  margin: 3px;
  border-radius: 2px;
  background-color: var(--segment-color); /* rgb(99, 102, 241, 0.15625); */
  opacity: 0.15625;
}

.recovery-code-input.-focused .segment[data-state="cursor"] div {
  flex: 0 0 2px;
  justify-self: center;
  margin: 8px auto;
  width: 2px;
  background: currentColor;
  animation: blink-caret 1.2s step-end infinite;
}
