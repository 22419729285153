.survey-side-out {
  position: fixed;
  display: flex;
  flex-direction: column;
  bottom: 0;
  left: 0;
  width: 28rem;
  height: 23rem;
  transition: height 0.5s;
  max-width: 100%;
  border: 0.0625rem solid #1f1f1f;
  border-top: 0;
  background-color: #fff;
  z-index: 1070;
}

.survey-side-out.is-closed {
  height: 2.5rem;
}

.survey-side-out-header {
  display: flex;
  justify-content: flex-end;
  min-height: 2.5rem;
  padding: 0.5rem;
  color: #fff;
  border: 0.0625rem solid #1f1f1f;
  background-color: #1f1f1f;
  cursor: pointer;
}

.survey-side-out-header-icons {
  padding: 0 1rem;
}

.survey-side-out-body {
  height: 100%;
  padding: 0.5rem;
  overflow: scroll;
}

.survey-side-out.is-closed .survey-side-out-body {
  display: none;
}

.survey-side-out-body .quiz,
.survey-fullscreen .quiz {
  margin-top: 0;
}

.survey-content-img {
  max-width: 100%;
  padding: 0 2rem;
  margin-bottom: 1rem;
}

.survey-content-img img {
  height: 20rem;
  max-width: 100%;
  display: block;
  margin: auto;
}

.survey-side-out .survey-content-img,
.survey-fullscreen .survey-content-img {
  max-width: 100%;
  height: auto;
}

.survey-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
}

.survey-content-td-img {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.survey-content-td-img.img-left,
.survey-content.img-right {
  justify-content: space-between;
}

.survey-content-td-img.img-left,
.survey-content-td-img.img-right {
  flex-direction: row;
}

.survey-content-td-right,
.survey-content-td-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.survey-content-input textarea {
  resize: none;
  overflow: hidden;
}

.survey-content-picture-choice {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.picture-choice {
  border-radius: 0.125rem;
  border: 0.0625rem solid #fc90bd;
  margin: 0 0.5rem 0.5rem;
  max-width: 9rem;
  background-color: rgb(252, 144, 189, 0.1);
  box-shadow: rgb(252, 144, 189, 0.6) 0rem 0rem 0rem 0.0625rem inset;
  color: rgb(252, 144, 189);
  cursor: pointer;
}

.picture-choice:hover {
  background-color: rgba(252, 144, 189, 0.3);
}

.picture-choice label {
  min-width: 8rem;
  background-color: rgb(252, 144, 189, 0.1);
  padding: 0.5rem;
}

.picture-choice.custom-control-img .custom-control-label::after {
  border-bottom-color: #fc90bd;
}

.picture-choice.is-selected.custom-control-img .custom-control-label::after {
  border-bottom-color: #fc90bd;
}

.picture-choice.custom-control-img .custom-control-label {
  opacity: 0.1;
}

.picture-choice.custom-control-img .custom-control-label.is-selectable {
  opacity: 1;
}

.picture-choice.is-selected {
  border: 0.0625rem solid #fc90bd;
  animation-name: blink-choice-selected;
  animation-duration: 0.4s;
  animation-timing-function: ease;
  animation-delay: 0s;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
}

@keyframes blink-choice-selected {
  0% {
    opacity: 0.3;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}
.picture-choice-label {
  border-top: 0.0625rem solid #fc90bd;
}

.picture-choice-selected-icon {
  position: absolute;
  right: 0rem;
  top: 0rem;
  z-index: 1;
  width: 3rem;
  height: 3rem;
  border-top-right-radius: 0.25rem;
  overflow: hidden;
  display: flex;
}

.picture-choice-selected-icon::before {
  content: "";
  display: block;
  width: 0rem;
  height: 0rem;
  border-style: solid;
  border-width: 1.5rem;
  border-image: none 100% / 1 / 0 stretch;
  border-color: #fc90bd #fc90bd transparent transparent;
  position: absolute;
  right: 0rem;
  top: 0rem;
}

.picture-choice-selected-icon .icon-wrap {
  position: absolute;
  right: 0.375rem;
  top: 0.4375rem;
}

.picture-choice-icon {
  display: flex;
  -moz-box-pack: center;
  justify-content: center;
  -moz-box-align: center;
  align-items: center;
}

.picture-choice-icon svg {
  display: block;
  fill: rgb(255, 255, 255);
  transition: fill 0.2s ease 0s;
}
