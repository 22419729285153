.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.42rem;
}
.alert-heading {
  color: inherit;
}
.alert-link {
  font-weight: 500;
}
.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}
.alert-primary {
  color: #1c5085;
  background-color: #d7ebff;
  border-color: #c7e2ff;
}
.alert-primary hr {
  border-top-color: #aed5ff;
}
.alert-primary .alert-link {
  color: #13375b;
}
.alert-secondary {
  color: #77787c;
  background-color: #fafafc;
  border-color: #f7f8fb;
}
.alert-secondary hr {
  border-top-color: #e6e9f3;
}
.alert-secondary .alert-link {
  color: #5e5f62;
}
.alert-success {
  color: #0e6662;
  background-color: #d1f3f2;
  border-color: #bfefed;
}
.alert-success hr {
  border-top-color: #abeae7;
}
.alert-success .alert-link {
  color: #083937;
}
.alert-info {
  color: #1c5085;
  background-color: #d7ebff;
  border-color: #c7e2ff;
}
.alert-info hr {
  border-top-color: #aed5ff;
}
.alert-info .alert-link {
  color: #13375b;
}
.alert-warning {
  color: #855700;
  background-color: #fec;
  border-color: #ffe7b8;
}
.alert-warning hr {
  border-top-color: #ffde9f;
}
.alert-warning .alert-link {
  color: #523600;
}
.alert-danger {
  color: #802932;
  background-color: #fddcdf;
  border-color: #fccdd2;
}
.alert-danger hr {
  border-top-color: #fbb5bc;
}
.alert-danger .alert-link {
  color: #591d23;
}
.alert-light {
  color: #7e8081;
  background-color: #fdfdfe;
  border-color: #fcfcfd;
}
.alert-light hr {
  border-top-color: #ededf3;
}
.alert-light .alert-link {
  color: #656667;
}
.alert-dark {
  color: #0c0f1a;
  background-color: #d1d2d6;
  border-color: #bebfc6;
}
.alert-dark hr {
  border-top-color: #b0b2ba;
}
.alert-dark .alert-link {
  color: #000;
}
.alert-white {
  color: #858585;
  background-color: #fff;
  border-color: #fff;
}
.alert-white hr {
  border-top-color: #f2f2f2;
}
.alert-white .alert-link {
  color: #6c6c6c;
}

.alert.alert-primary {
  background-color: #3699ff;
  border-color: #3699ff;
  color: #fff;
}
.alert.alert-primary .close i {
  opacity: 0.8;
  color: #fff;
}
.alert.alert-primary .close:focus i,
.alert.alert-primary .close:hover i {
  opacity: 1;
  color: #fff;
}
.alert.alert-secondary {
  background-color: #e4e6ef;
  border-color: #e4e6ef;
  color: #3f4254;
}
.alert.alert-secondary .close i {
  opacity: 0.8;
  color: #3f4254;
}
.alert.alert-secondary .close:focus i,
.alert.alert-secondary .close:hover i {
  opacity: 1;
  color: #3f4254;
}
.alert.alert-success {
  background-color: #1bc5bd;
  border-color: #1bc5bd;
  color: #fff;
}
.alert.alert-success .close i {
  opacity: 0.8;
  color: #fff;
}
.alert.alert-success .close:focus i,
.alert.alert-success .close:hover i {
  opacity: 1;
  color: #fff;
}
.alert.alert-info {
  background-color: #3699ff;
  border-color: #3699ff;
  color: #fff;
}
.alert.alert-info .close i {
  opacity: 0.8;
  color: #fff;
}
.alert.alert-info .close:focus i,
.alert.alert-info .close:hover i {
  opacity: 1;
  color: #fff;
}
.alert.alert-warning {
  background-color: #ffa800;
  border-color: #ffa800;
  color: #fff;
}
.alert.alert-warning .close i {
  opacity: 0.8;
  color: #fff;
}
.alert.alert-warning .close:focus i,
.alert.alert-warning .close:hover i {
  opacity: 1;
  color: #fff;
}
.alert.alert-danger {
  background-color: #f64e60;
  border-color: #f64e60;
  color: #fff;
}
.alert.alert-danger .close i {
  opacity: 0.8;
  color: #fff;
}
.alert.alert-danger .close:focus i,
.alert.alert-danger .close:hover i {
  opacity: 1;
  color: #fff;
}
.alert.alert-light {
  background-color: #f3f6f9;
  border-color: #f3f6f9;
  color: #7e8299;
}
.alert.alert-light .close i {
  opacity: 0.8;
  color: #7e8299;
}
.alert.alert-light .close:focus i,
.alert.alert-light .close:hover i {
  opacity: 1;
  color: #7e8299;
}
.alert.alert-dark {
  background-color: #181c32;
  border-color: #181c32;
  color: #fff;
}
.alert.alert-dark .close i {
  opacity: 0.8;
  color: #fff;
}
.alert.alert-dark .close:focus i,
.alert.alert-dark .close:hover i {
  opacity: 1;
  color: #fff;
}
.alert.alert-white {
  background-color: #fff;
  border-color: #fff;
  color: #3f4254;
}
.alert.alert-white .close i {
  opacity: 0.8;
  color: #3f4254;
}
.alert.alert-white .close:focus i,
.alert.alert-white .close:hover i {
  opacity: 1;
  color: #3f4254;
}
.alert.alert-custom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  padding: 1.5rem 2rem;
}
.alert.alert-custom .alert-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 1.25rem 0 0;
}
.alert.alert-custom .alert-icon i {
  font-size: 2rem;
}
.alert.alert-custom .alert-icon.alert-icon-top {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
}
.alert.alert-custom .alert-text {
  -ms-flex-item-align: center;
  align-self: center;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.alert.alert-custom .alert-close {
  padding: 0 0 0 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  line-height: 0;
}
.alert.alert-custom .alert-close .close {
  text-shadow: none !important;
  cursor: pointer;
  line-height: 0;
}
.alert.alert-custom .alert-close i {
  -webkit-transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease,
    -webkit-box-shadow 0.15s ease;
  font-size: 1rem;
}
.alert.alert-custom .alert-close i.ki {
  font-size: 0.75rem;
}
.alert.alert-custom .alert-close:focus i,
.alert.alert-custom .alert-close:hover i {
  -webkit-transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease,
    -webkit-box-shadow 0.15s ease;
}
.alert.alert-custom.alert-outline-2x {
  border-width: 2px;
}
.alert.alert-custom.alert-white {
  background-color: #fff;
  border-color: #fff;
}
.alert.alert-custom.alert-white .alert-icon i {
  color: #3f4254;
}
.alert.alert-custom.alert-white .alert-text {
  color: #3f4254;
}
.alert.alert-custom.alert-white .alert-close i {
  color: #3f4254;
}
.alert.alert-custom.alert-default {
  background-color: #f3f6f9;
  border-color: #f3f6f9;
}
.alert.alert-custom.alert-shadow {
  -webkit-box-shadow: 0 0 30px 0 rgba(82, 63, 105, 0.05);
  box-shadow: 0 0 30px 0 rgba(82, 63, 105, 0.05);
}
.alert.alert-custom.alert-primary {
  background-color: #3699ff;
  border-color: #3699ff;
}
.alert.alert-custom.alert-primary .alert-icon i {
  opacity: 0.8;
  color: #fff;
}
.alert.alert-custom.alert-primary .alert-text {
  color: #fff;
}
.alert.alert-custom.alert-primary .alert-close i {
  color: #fff;
}
.alert.alert-custom.alert-primary .alert-close:focus,
.alert.alert-custom.alert-primary .alert-close:hover {
  opacity: 1;
  color: #fff;
}
.alert.alert-custom.alert-light-primary {
  background-color: #e1f0ff;
  border-color: transparent;
}
.alert.alert-custom.alert-light-primary .alert-icon i {
  color: #3699ff;
}
.alert.alert-custom.alert-light-primary .alert-text {
  color: #3699ff;
}
.alert.alert-custom.alert-light-primary .alert-close i {
  color: #3699ff;
}
.alert.alert-custom.alert-light-primary .alert-close:focus,
.alert.alert-custom.alert-light-primary .alert-close:hover {
  color: #187de4;
}
.alert.alert-custom.alert-light-primary.alert-notice {
  border: 0;
  border-left: 4px solid #3699ff;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.alert.alert-custom.alert-outline-primary {
  background-color: transparent;
  border-color: #3699ff;
}
.alert.alert-custom.alert-outline-primary .alert-icon i {
  color: #3699ff;
}
.alert.alert-custom.alert-outline-primary .alert-text {
  color: #3699ff;
}
.alert.alert-custom.alert-outline-primary .alert-close i {
  opacity: 0.8;
  color: #3699ff;
}
.alert.alert-custom.alert-outline-primary .alert-close:focus i,
.alert.alert-custom.alert-outline-primary .alert-close:hover i {
  opacity: 1;
}
.alert.alert-custom.alert-secondary {
  background-color: #e4e6ef;
  border-color: #e4e6ef;
}
.alert.alert-custom.alert-secondary .alert-icon i {
  opacity: 0.8;
  color: #3f4254;
}
.alert.alert-custom.alert-secondary .alert-text {
  color: #3f4254;
}
.alert.alert-custom.alert-secondary .alert-close i {
  color: #3f4254;
}
.alert.alert-custom.alert-secondary .alert-close:focus,
.alert.alert-custom.alert-secondary .alert-close:hover {
  opacity: 1;
  color: #3f4254;
}
.alert.alert-custom.alert-light-secondary {
  background-color: #ebedf3;
  border-color: transparent;
}
.alert.alert-custom.alert-light-secondary .alert-icon i {
  color: #e4e6ef;
}
.alert.alert-custom.alert-light-secondary .alert-text {
  color: #e4e6ef;
}
.alert.alert-custom.alert-light-secondary .alert-close i {
  color: #e4e6ef;
}
.alert.alert-custom.alert-light-secondary .alert-close:focus,
.alert.alert-custom.alert-light-secondary .alert-close:hover {
  color: #d7dae7;
}
.alert.alert-custom.alert-light-secondary.alert-notice {
  border: 0;
  border-left: 4px solid #e4e6ef;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.alert.alert-custom.alert-outline-secondary {
  background-color: transparent;
  border-color: #e4e6ef;
}
.alert.alert-custom.alert-outline-secondary .alert-icon i {
  color: #3f4254;
}
.alert.alert-custom.alert-outline-secondary .alert-text {
  color: #3f4254;
}
.alert.alert-custom.alert-outline-secondary .alert-close i {
  opacity: 0.8;
  color: #3f4254;
}
.alert.alert-custom.alert-outline-secondary .alert-close:focus i,
.alert.alert-custom.alert-outline-secondary .alert-close:hover i {
  opacity: 1;
}
.alert.alert-custom.alert-success {
  background-color: #1bc5bd;
  border-color: #1bc5bd;
}
.alert.alert-custom.alert-success .alert-icon i {
  opacity: 0.8;
  color: #fff;
}
.alert.alert-custom.alert-success .alert-text {
  color: #fff;
}
.alert.alert-custom.alert-success .alert-close i {
  color: #fff;
}
.alert.alert-custom.alert-success .alert-close:focus,
.alert.alert-custom.alert-success .alert-close:hover {
  opacity: 1;
  color: #fff;
}
.alert.alert-custom.alert-light-success {
  background-color: #c9f7f5;
  border-color: transparent;
}
.alert.alert-custom.alert-light-success .alert-icon i {
  color: #1bc5bd;
}
.alert.alert-custom.alert-light-success .alert-text {
  color: #1bc5bd;
}
.alert.alert-custom.alert-light-success .alert-close i {
  color: #1bc5bd;
}
.alert.alert-custom.alert-light-success .alert-close:focus,
.alert.alert-custom.alert-light-success .alert-close:hover {
  color: #0bb7af;
}
.alert.alert-custom.alert-light-success.alert-notice {
  border: 0;
  border-left: 4px solid #1bc5bd;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.alert.alert-custom.alert-outline-success {
  background-color: transparent;
  border-color: #1bc5bd;
}
.alert.alert-custom.alert-outline-success .alert-icon i {
  color: #1bc5bd;
}
.alert.alert-custom.alert-outline-success .alert-text {
  color: #1bc5bd;
}
.alert.alert-custom.alert-outline-success .alert-close i {
  opacity: 0.8;
  color: #1bc5bd;
}
.alert.alert-custom.alert-outline-success .alert-close:focus i,
.alert.alert-custom.alert-outline-success .alert-close:hover i {
  opacity: 1;
}
.alert.alert-custom.alert-info {
  background-color: #3699ff;
  border-color: #3699ff;
}
.alert.alert-custom.alert-info .alert-icon i {
  opacity: 0.8;
  color: #fff;
}
.alert.alert-custom.alert-info .alert-text {
  color: #fff;
}
.alert.alert-custom.alert-info .alert-close i {
  color: #fff;
}
.alert.alert-custom.alert-info .alert-close:focus,
.alert.alert-custom.alert-info .alert-close:hover {
  opacity: 1;
  color: #fff;
}
.alert.alert-custom.alert-light-info {
  background-color: #e1f0ff;
  border-color: transparent;
}
.alert.alert-custom.alert-light-info .alert-icon i {
  color: #3699ff;
}
.alert.alert-custom.alert-light-info .alert-text {
  color: #3699ff;
}
.alert.alert-custom.alert-light-info .alert-close i {
  color: #3699ff;
}
.alert.alert-custom.alert-light-info .alert-close:focus,
.alert.alert-custom.alert-light-info .alert-close:hover {
  color: #187de4;
}
.alert.alert-custom.alert-light-info.alert-notice {
  border: 0;
  border-left: 4px solid #3699ff;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.alert.alert-custom.alert-outline-info {
  background-color: transparent;
  border-color: #3699ff;
}
.alert.alert-custom.alert-outline-info .alert-icon i {
  color: #3699ff;
}
.alert.alert-custom.alert-outline-info .alert-text {
  color: #3699ff;
}
.alert.alert-custom.alert-outline-info .alert-close i {
  opacity: 0.8;
  color: #3699ff;
}
.alert.alert-custom.alert-outline-info .alert-close:focus i,
.alert.alert-custom.alert-outline-info .alert-close:hover i {
  opacity: 1;
}
.alert.alert-custom.alert-warning {
  background-color: #ffa800;
  border-color: #ffa800;
}
.alert.alert-custom.alert-warning .alert-icon i {
  opacity: 0.8;
  color: #fff;
}
.alert.alert-custom.alert-warning .alert-text {
  color: #fff;
}
.alert.alert-custom.alert-warning .alert-close i {
  color: #fff;
}
.alert.alert-custom.alert-warning .alert-close:focus,
.alert.alert-custom.alert-warning .alert-close:hover {
  opacity: 1;
  color: #fff;
}
.alert.alert-custom.alert-light-warning {
  background-color: #fff4de;
  border-color: transparent;
}
.alert.alert-custom.alert-light-warning .alert-icon i {
  color: #ffa800;
}
.alert.alert-custom.alert-light-warning .alert-text {
  color: #ffa800;
}
.alert.alert-custom.alert-light-warning .alert-close i {
  color: #ffa800;
}
.alert.alert-custom.alert-light-warning .alert-close:focus,
.alert.alert-custom.alert-light-warning .alert-close:hover {
  color: #ee9d01;
}
.alert.alert-custom.alert-light-warning.alert-notice {
  border: 0;
  border-left: 4px solid #ffa800;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.alert.alert-custom.alert-outline-warning {
  background-color: transparent;
  border-color: #ffa800;
}
.alert.alert-custom.alert-outline-warning .alert-icon i {
  color: #ffa800;
}
.alert.alert-custom.alert-outline-warning .alert-text {
  color: #ffa800;
}
.alert.alert-custom.alert-outline-warning .alert-close i {
  opacity: 0.8;
  color: #ffa800;
}
.alert.alert-custom.alert-outline-warning .alert-close:focus i,
.alert.alert-custom.alert-outline-warning .alert-close:hover i {
  opacity: 1;
}
.alert.alert-custom.alert-danger {
  background-color: #f64e60;
  border-color: #f64e60;
}
.alert.alert-custom.alert-danger .alert-icon i {
  opacity: 0.8;
  color: #fff;
}
.alert.alert-custom.alert-danger .alert-text {
  color: #fff;
}
.alert.alert-custom.alert-danger .alert-close i {
  color: #fff;
}
.alert.alert-custom.alert-danger .alert-close:focus,
.alert.alert-custom.alert-danger .alert-close:hover {
  opacity: 1;
  color: #fff;
}
.alert.alert-custom.alert-light-danger {
  background-color: #ffe2e5;
  border-color: transparent;
}
.alert.alert-custom.alert-light-danger .alert-icon i {
  color: #f64e60;
}
.alert.alert-custom.alert-light-danger .alert-text {
  color: #f64e60;
}
.alert.alert-custom.alert-light-danger .alert-close i {
  color: #f64e60;
}
.alert.alert-custom.alert-light-danger .alert-close:focus,
.alert.alert-custom.alert-light-danger .alert-close:hover {
  color: #ee2d41;
}
.alert.alert-custom.alert-light-danger.alert-notice {
  border: 0;
  border-left: 4px solid #f64e60;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.alert.alert-custom.alert-outline-danger {
  background-color: transparent;
  border-color: #f64e60;
}
.alert.alert-custom.alert-outline-danger .alert-icon i {
  color: #f64e60;
}
.alert.alert-custom.alert-outline-danger .alert-text {
  color: #f64e60;
}
.alert.alert-custom.alert-outline-danger .alert-close i {
  opacity: 0.8;
  color: #f64e60;
}
.alert.alert-custom.alert-outline-danger .alert-close:focus i,
.alert.alert-custom.alert-outline-danger .alert-close:hover i {
  opacity: 1;
}
.alert.alert-custom.alert-light {
  background-color: #f3f6f9;
  border-color: #f3f6f9;
}
.alert.alert-custom.alert-light .alert-icon i {
  opacity: 0.8;
  color: #7e8299;
}
.alert.alert-custom.alert-light .alert-text {
  color: #7e8299;
}
.alert.alert-custom.alert-light .alert-close i {
  color: #7e8299;
}
.alert.alert-custom.alert-light .alert-close:focus,
.alert.alert-custom.alert-light .alert-close:hover {
  opacity: 1;
  color: #7e8299;
}
.alert.alert-custom.alert-light-light {
  background-color: #f3f6f9;
  border-color: transparent;
}
.alert.alert-custom.alert-light-light .alert-icon i {
  color: #f3f6f9;
}
.alert.alert-custom.alert-light-light .alert-text {
  color: #f3f6f9;
}
.alert.alert-custom.alert-light-light .alert-close i {
  color: #f3f6f9;
}
.alert.alert-custom.alert-light-light .alert-close:focus,
.alert.alert-custom.alert-light-light .alert-close:hover {
  color: #e4e6ef;
}
.alert.alert-custom.alert-light-light.alert-notice {
  border: 0;
  border-left: 4px solid #f3f6f9;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.alert.alert-custom.alert-outline-light {
  background-color: transparent;
  border-color: #f3f6f9;
}
.alert.alert-custom.alert-outline-light .alert-icon i {
  color: #3f4254;
}
.alert.alert-custom.alert-outline-light .alert-text {
  color: #3f4254;
}
.alert.alert-custom.alert-outline-light .alert-close i {
  opacity: 0.8;
  color: #3f4254;
}
.alert.alert-custom.alert-outline-light .alert-close:focus i,
.alert.alert-custom.alert-outline-light .alert-close:hover i {
  opacity: 1;
}
.alert.alert-custom.alert-dark {
  background-color: #181c32;
  border-color: #181c32;
}
.alert.alert-custom.alert-dark .alert-icon i {
  opacity: 0.8;
  color: #fff;
}
.alert.alert-custom.alert-dark .alert-text {
  color: #fff;
}
.alert.alert-custom.alert-dark .alert-close i {
  color: #fff;
}
.alert.alert-custom.alert-dark .alert-close:focus,
.alert.alert-custom.alert-dark .alert-close:hover {
  opacity: 1;
  color: #fff;
}
.alert.alert-custom.alert-light-dark {
  background-color: #d1d3e0;
  border-color: transparent;
}
.alert.alert-custom.alert-light-dark .alert-icon i {
  color: #181c32;
}
.alert.alert-custom.alert-light-dark .alert-text {
  color: #181c32;
}
.alert.alert-custom.alert-light-dark .alert-close i {
  color: #181c32;
}
.alert.alert-custom.alert-light-dark .alert-close:focus,
.alert.alert-custom.alert-light-dark .alert-close:hover {
  color: #131628;
}
.alert.alert-custom.alert-light-dark.alert-notice {
  border: 0;
  border-left: 4px solid #181c32;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.alert.alert-custom.alert-outline-dark {
  background-color: transparent;
  border-color: #181c32;
}
.alert.alert-custom.alert-outline-dark .alert-icon i {
  color: #181c32;
}
.alert.alert-custom.alert-outline-dark .alert-text {
  color: #181c32;
}
.alert.alert-custom.alert-outline-dark .alert-close i {
  opacity: 0.8;
  color: #181c32;
}
.alert.alert-custom.alert-outline-dark .alert-close:focus i,
.alert.alert-custom.alert-outline-dark .alert-close:hover i {
  opacity: 1;
}
.alert.alert-custom.alert-white {
  background-color: #fff;
  border-color: #fff;
}
.alert.alert-custom.alert-white .alert-icon i {
  opacity: 0.8;
  color: #3f4254;
}
.alert.alert-custom.alert-white .alert-text {
  color: #3f4254;
}
.alert.alert-custom.alert-white .alert-close i {
  color: #3f4254;
}
.alert.alert-custom.alert-white .alert-close:focus,
.alert.alert-custom.alert-white .alert-close:hover {
  opacity: 1;
  color: #3f4254;
}
.alert.alert-custom.alert-light-white {
  background-color: #fff;
  border-color: transparent;
}
.alert.alert-custom.alert-light-white .alert-icon i {
  color: #fff;
}
.alert.alert-custom.alert-light-white .alert-text {
  color: #fff;
}
.alert.alert-custom.alert-light-white .alert-close i {
  color: #fff;
}
.alert.alert-custom.alert-light-white .alert-close:focus,
.alert.alert-custom.alert-light-white .alert-close:hover {
  color: #f3f6f9;
}
.alert.alert-custom.alert-light-white.alert-notice {
  border: 0;
  border-left: 4px solid #fff;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.alert.alert-custom.alert-outline-white {
  background-color: transparent;
  border-color: #fff;
}
.alert.alert-custom.alert-outline-white .alert-icon i {
  color: #fff;
}
.alert.alert-custom.alert-outline-white .alert-text {
  color: #fff;
}
.alert.alert-custom.alert-outline-white .alert-close i {
  opacity: 0.8;
  color: #fff;
}
.alert.alert-custom.alert-outline-white .alert-close:focus i,
.alert.alert-custom.alert-outline-white .alert-close:hover i {
  opacity: 1;
}

.alert[data-notify] {
  min-width: 300px;
  padding: 1rem 1.5rem;
}
.alert[data-notify] .close {
  right: 1rem !important;
  padding: 0.25rem 0 0 2rem;
  font-weight: 300;
}
.alert[data-notify] .close:before {
  font-size: 0.75rem;
  color: #fff;
  opacity: 0.7;
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  font-family: Ki;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  content: "ï„°";
}
.alert[data-notify] .close:hover:before {
  opacity: 1;
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}
@media (max-width: 991.98px) {
  .alert[data-notify] {
    max-width: 70%;
  }
}
@media (max-width: 991.98px) {
  .alert[data-notify] {
    max-width: 90%;
  }
}
.alert[data-notify][data-notify-position="bottom-center"],
.alert[data-notify][data-notify-position="top-center"] {
  width: 30%;
}
@media (max-width: 991.98px) {
  .alert[data-notify][data-notify-position="bottom-center"],
  .alert[data-notify][data-notify-position="top-center"] {
    width: 70%;
  }
}
@media (max-width: 991.98px) {
  .alert[data-notify][data-notify-position="bottom-center"],
  .alert[data-notify][data-notify-position="top-center"] {
    width: 90%;
  }
}
.alert[data-notify] .icon {
  position: absolute;
}
.alert[data-notify] [data-notify="title"] {
  display: block;
  font-weight: 500;
}
.alert[data-notify] .icon ~ [data-notify="title"] {
  padding-left: 2.85rem;
}
.alert[data-notify] .icon ~ [data-notify="message"] {
  display: inline-block;
  padding-left: 2.85rem;
}
.alert[data-notify]
  [data-notify="title"]:not(:empty)
  ~ [data-notify="message"] {
  margin-top: 0.2rem;
}
.alert[data-notify] .progress {
  margin-top: 0.5rem;
  line-height: 0.5rem;
  height: 0.5rem;
}
