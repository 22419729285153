.input-error {
  display: block;
  color: #bb0000;
}

.input-error:before {
  font-family: "Font Awesome 5 Free";
  content: "\f071";
  display: inline-block;
  padding-right: 0.5rem;
  font-weight: 900;
}
