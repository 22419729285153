/*Fonts*/
@font-face {
  font-family: "quartz-regular";
  src: url("/fonts/quartz/QuartzRegular.ttf");
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* Input placeholders */
::-webkit-input-placeholder {
  /* WebKit browsers */
  text-transform: none;
}
:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  text-transform: none;
}
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  text-transform: none;
}
:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  text-transform: none;
}

/*Element Level Styles*/
select {
  -moz-appearance: none;
  text-indent: 0.01px;
  text-overflow: "";
}

/*Sections*/
.section {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

/*Word breaks*/
.word-break-all {
  word-break: break-all;
}

/*Prevent page scroll on "modal open"*/
.modal-open {
  overflow: initial;
}

/*Mobile Specific*/
@media only screen and (max-width: 320px) {
  .iphone-5-cart {
    margin-left: 17rem;
    margin-top: -2.6rem;
  }
}

.mobile-cbb-icon {
  font-size: 1.4rem;
}

/*Homepage*/
.image-with-description {
  position: relative;
}

.image-with-description img {
  border-radius: 10px;
}

.image-with-description .common-list-items {
  padding-top: 1rem;
}

.image-with-description-shape-bg {
  right: -8%;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  top: 20%;
  z-index: 0;
}

/* .image-with-description-block {
	padding: 6.5rem 0 6.5rem;
} */

/*Hero Images*/
.hero-image.greyBackground .img-container {
  background-color: #767676;
}
.hero-image .row.reset-grid-margins {
  margin-left: 0;
  margin-right: 0;
}
.hero-image .img-container {
  transition: all 0.2s;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  height: auto;
  padding: 0;
}
@media screen and (max-width: 767px) {
  .hero-image .img-container {
    position: relative;
  }
  .hero-image .img-container:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 33.33333%;
  }
  .hero-image .img-container > * {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .hero-image .img-container.vertical-ratio {
    position: relative;
  }
  .hero-image .img-container.vertical-ratio:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 125%;
  }
  .hero-image .img-container.vertical-ratio > * {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .hero-image .img-container.mobile-box-ratio {
    position: relative;
  }
  .hero-image .img-container.mobile-box-ratio:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 66.66667%;
  }
  .hero-image .img-container.mobile-box-ratio > * {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
@media screen and (min-width: 768px) {
  .hero-image .img-container {
    position: relative;
  }
  .hero-image .img-container:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 20%;
  }
  .hero-image .img-container > * {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .hero-image .img-container.vertical-ratio {
    position: relative;
  }
  .hero-image .img-container.vertical-ratio:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 125%;
  }
  .hero-image .img-container.vertical-ratio > * {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
.hero-image .img-container.loading {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: loadingBackgroundAnimation;
  animation-name: loadingBackgroundAnimation;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  background: #f6f7f8;
  background-image: -webkit-linear-gradient(
    left,
    #f6f7f8,
    #edeef1 20%,
    #f6f7f8 40%,
    #f6f7f8
  );
  background-repeat: no-repeat;
  background-size: 100%;
}
/*Navbar*/
.sticky-top.sticky-l1,
.z-index-custom-1 {
  z-index: 1010;
}

.sticky-top.sticky-l2,
.z-index-custom-2 {
  z-index: 1030;
}

.sticky-top.sticky-l3,
.z-index-custom-3 {
  z-index: 1050;
}

/*Links*/
.styled-underlined-link {
  cursor: pointer;
  color: #1f1f1f;
  border-bottom: 2px solid #fc90bd;
  width: fit-content;
  width: -moz-fit-content;
  width: -webkit-fit-content;
}

.styled-underlined-link:hover {
  color: #1f1f1f;
  border-bottom: 2px solid #fc4e96;
}

/*Component Specific*/

/*Address Book*/

.list-address-book-item.active {
  background-color: #fc90bd;
}

/* Recaptcha */
.grecaptcha-badge {
  visibility: hidden;
}

/*Miscellaneous*/
.not-allowed {
  cursor: not-allowed !important;
  pointer-events: all !important;
}

/*Centering*/
.fixed-center {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}

.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.horizontal-center {
  position: relative;
  left: 50%;
  transform: translate(-50%);
}

.vertical-center {
  position: relative;
  top: 50%;
  transform: translate(0%, -50%);
}

/*Clicking*/
.clickable {
  cursor: pointer !important;
}

/*Additional Border Styling*/
.dark-border-wrapper {
  border: 1px solid #1f1f1f;
}

/*Hide Dropdown carets*/
.caret-off::before {
  display: none;
}
.caret-off::after {
  display: none;
}

/*Out of stock | Unavailable*/
.product-unavailable,
.product-out-of-stock {
  opacity: 0.5;
}

/*Get Help Button*/
.btn-get-help {
  display: block;
  position: fixed;
  bottom: 1.25rem;
  right: 1.875rem;
  z-index: 99;
  font-size: 1.125rem;
  cursor: pointer;
  border-radius: 2.5rem;
}

/*Apply Quartz Font*/
.quartz-regular {
  font-family: "quartz-regular", sans-serif;
}
