/* Quiz Step Indicators  */

.quiz-step-indicators {
  position: absolute;
  bottom: 10px;
  left: 50%;
  z-index: 15;
  width: 60%;
  padding-left: 0;
  margin-bottom: -3rem;
  margin-left: -30%;
  text-align: center;
  list-style: none;
}

.quiz-step-indicators > li {
  display: inline-block;
  width: 7px;
  height: 7px;
  margin: 4px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #ddd;
  border: 1px solid #ddd;
  border-radius: 10px;
}

.quiz-step-indicators > li.active {
  width: 8px;
  height: 8px;
  background-color: #fc90bd;
}

@media screen and (max-width: 320px) {
  .quiz-step-indicators {
    width: 70%;
  }
}

@media screen and (min-width: 768px) {
  .quiz-step-indicators {
    bottom: 20px;
  }
}

@media screen and (min-width: 1024px) {
  .quiz-step-indicators > li {
    width: 10px;
    height: 10px;
  }

  .quiz-step-indicators > li.active {
    width: 11px;
    height: 11px;
  }
}

/* Main Quiz CSS */

.quiz {
  margin-top: 6rem;
}

.quiz-questions .slide-indicator {
  position: static;
  margin: 30px 0 15px;
}
.quiz-questions .slide-indicator li {
  margin: 0 4px;
}
.quiz-questions .slide-indicator li.active {
  background-color: #ef447f;
}
.quiz-questions .slide-indicator span {
  display: none;
  color: #ddd;
}
.quiz-questions .label-bottom {
  font-size: 12px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 5px 0;
  background: rgba(0, 0, 0, 0.65);
  text-transform: uppercase;
  text-align: center;
  color: #fff;
}
.toolbar-control {
  margin-bottom: 100px;
}
.toolbar-control .single {
  width: 60%;
  margin-left: 20%;
  margin-right: 20%;
}
.quiz-take {
  background-color: #fff;
  height: 100%;
}
.quiz {
  text-align: center;
}
.quiz .question {
  padding: 0 0 25px;
}
.quiz .answer-container {
  position: relative;
}
.quiz .answer-container .check-mark,
.quiz .answer-container .cbb-check {
  display: none;
}
.quiz .answer-container .check-mark.checked,
.quiz .answer-container .cbb-check.checked {
  display: block;
  position: absolute;
}
.quiz .answer-container .check-mark {
  top: 0;
  left: 15px;
  width: 30px;
  height: 30px;
  z-index: 2;
}
.quiz .answer-container .cbb-check {
  font-size: 18px;
  top: 20px;
  left: calc(50% - 11px);
  color: #fff;
}
.quiz .subquestion-wrapper .question-name {
  font-family: NeueEinstellungBold;
  font-size: 24px;
  margin: 0 5% 2%;
  line-height: 1.5;
}
.quiz .subquestion-wrapper .answer-inner-wrapper {
  margin-left: -10px;
  margin-right: -10px;
}
.quiz .subquestion-wrapper .answer-inner-wrapper [class*=" col-"],
.quiz .subquestion-wrapper .answer-inner-wrapper [class^="col-"] {
  padding: 0 5px;
}
.quiz .subquestion-wrapper .answer-inner-wrapper .answer-label {
  text-transform: uppercase;
  font-size: 12px;
  margin: 5px auto 15px;
}
.quiz .subquestion-wrapper .answer-container .answer-image {
  margin-top: 2px;
}
.quiz .subquestion-wrapper .answer-container .check-mark {
  top: -25px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.quiz .btn-outline.quiz-back-btn {
  padding-top: 16px;
  padding-bottom: 16px;
}
.quiz .quiz-next-btn-wrapper {
  padding-left: 10px;
}
.quiz .quiz-questions h2 {
  color: #fff;
}
.quiz .quiz-questions .toolbar-control {
  clear: both;
  margin: 0 auto 60px;
  padding: 15px;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  text-align: center;
}
.quiz .quiz-questions .toolbar-control div {
  float: left;
  width: 100%;
  text-align: center;
}
.quiz .quiz-questions .toolbar-control .back {
  width: calc(33% - 10px);
  margin-right: 10px;
}
.quiz .quiz-questions .toolbar-control .forward {
  width: calc(67% - 10px);
}
.quiz .quiz-questions .carousel {
  height: auto;
  white-space: normal;
  padding-left: 0;
}
.quiz .carousel {
  padding-left: 0;
  padding-right: 0;
}
.quiz .carousel .slide-elem {
  text-align: center;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}
.quiz .slide-indicator {
  padding: 0;
}
.quiz .choice .label {
  font-size: 12px;
  text-align: center;
  line-height: 1.5;
}
.quiz .answer-swatch {
  overflow: hidden;
  margin: 0 auto;
  width: 274px;
}
.quiz .answer-swatch.single .choice .answer-image {
  display: none;
}
.quiz .answer-swatch .choice {
  display: inline-block;
  overflow: hidden;
  float: left;
  margin: 0 9px 20px;
  outline: none;
  cursor: pointer;
}
.quiz .answer-swatch .choice .label {
  width: 73px;
  text-transform: uppercase;
}
.quiz .answer-swatch .swatch {
  width: 60px;
  height: 60px;
  -webkit-border-radius: 60px;
  -moz-border-radius: 60px;
  border-radius: 60px;
  border: 0;
  margin: 5px auto;
  padding: 0;
}
.quiz .answer-swatch .answer-label {
  text-align: center;
  text-transform: uppercase;
  font-family: NeueEinstellungRegular;
  font-size: 10px;
  margin-bottom: 20px;
}
.quiz .block6 .answer-container .check-mark,
.quiz .block12 .answer-container .check-mark {
  top: 15px;
  left: 15px;
}
.quiz .answer-frequency,
.quiz .answer-radio {
  text-align: left;
  overflow: hidden;
}
.quiz .answer-frequency .radio-button,
.quiz .answer-radio .radio-button {
  margin-right: 10px;
}
.quiz .answer-frequency .radio-button + *,
.quiz .answer-radio .radio-button + * {
  overflow: hidden;
}
.quiz .answer-frequency .h6,
.quiz .answer-radio .h6 {
  font-family: NeueEinstellungBold;
  font-size: 1.4rem;
  line-height: 1.5;
  margin-bottom: 10px;
}
.quiz .answer-frequency .h6 + p,
.quiz .answer-radio .h6 + p {
  margin-bottom: 0;
}
.quiz .answer-radio .choice {
  padding: 20px;
  border-bottom: 1px solid #ddd;
}
.quiz .answer-radio .choice:last-child {
  border-bottom: none;
}
.quiz .answer-frequency {
  display: flex;
}
.quiz .answer-frequency .answer-frequency-thumbnail-wrapper {
  width: 50%;
}
.quiz
  .answer-frequency
  .answer-frequency-thumbnail-wrapper
  .answer-frequency-thumbnail-image {
  position: relative;
  display: block;
  width: 100%;
  padding-top: 62.5%;
}
.quiz
  .answer-frequency
  .answer-frequency-thumbnail-wrapper
  .answer-frequency-thumbnail-image
  > * {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.quiz .answer-frequency .choice-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex-wrap: wrap;
  overflow: hidden;
  padding-left: 15px;
}
.quiz .answer-wrap.block6 .choice {
  box-sizing: border-box;
  border: 1px solid transparent;
}
.quiz .answer-wrap .choice {
  float: left;
  width: 50%;
  overflow: hidden;
}
.quiz .answer-wrap .choice .answer-image-wrapper {
  padding-top: calc(100% - 20px);
}
.quiz .answer-wrap .choice .answer-image-wrapper .answer-image {
  width: 100%;
}
.quiz .answer-wrap .choice .answer-image,
.quiz .answer-wrap .choice .label {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.quiz .answer-wrap .choice .label {
  height: 20%;
  padding-top: 75%;
  padding-bottom: 7%;
  overflow: hidden;
  background-image: url(/images/v3/quiz_gradient.png);
  background-repeat: repeat-x;
  background-size: 100% 100%;
  background-position: 0 100%;
  text-transform: uppercase;
  color: #fff;
}
.quiz .answer-wrap.describe-hair .choice .answer-image {
  top: -47%;
}
.quiz .answer-wrap.no-gradient .choice .label {
  background-image: none;
}
.quiz .answer-wrap.stack .choice {
  width: 100%;
}
.quiz .answer-wrap.stack .choice .label {
  padding: 20px;
  margin-left: 80px;
  background-image: none;
  text-transform: none;
  text-align: left;
  height: auto;
  position: relative;
  box-sizing: border-box;
}
.quiz .answer-wrap.stack .choice .label h1 {
  font-family: NeueEinstellungBold;
  margin: 0 0 5px;
  font-size: 14px;
  text-transform: uppercase;
}
.quiz .answer-wrap.stack .choice .label h2 {
  margin: 0;
  font-size: 14px;
}
.quiz .answer-wrap.stack .choice .label.white h1,
.quiz .answer-wrap.stack .choice .label.white h2 {
  color: #fff;
}
.quiz .answer-wrap.stack .choice .label.gray-darkest h1,
.quiz .answer-wrap.stack .choice .label.gray-darkest h2 {
  color: #333;
}
.quiz .answer-wrap.stack .choice.border {
  border-left: none;
  border-right: none;
  border-top: none;
}
.quiz .answer-wrap.stack .choice.border.first {
  border-top: 1px solid #ddd;
}
.quiz .answer-wrap.stack .choice .answer-image-wrapper {
  padding-top: 0;
}
.quiz .answer-wrap.stack .choice .answer-image-wrapper .answer-image {
  top: 12px;
  left: 18px;
  width: 65px;
}
.quiz .answer-wrap.stack.cards .choice .answer-image {
  position: relative;
  top: 0;
  margin: 0;
  float: left;
  padding: 10px 0;
}
.quiz .answer-wrap.stack.cards .answer-container .check-mark {
  top: 15px;
  left: auto;
  right: 15px;
}
.quiz .answer-grid,
.quiz .answer-grid-compact {
  overflow: hidden;
  margin-bottom: -2px;
}
.quiz .answer-grid-compact .answer-container .check-mark,
.quiz .answer-grid .answer-container .check-mark {
  top: calc(50% - 15px);
  left: calc(50% - 15px);
}
.quiz .answer-grid-compact .choice,
.quiz .answer-grid .choice {
  float: left;
  width: 49.35%;
  margin: 0;
  border: 1px solid #ebeced;
  overflow: hidden;
}
.quiz .answer-grid .choice .answer-image {
  padding: 30px 0;
}
.quiz .answer-text .choice {
  float: left;
  width: 50%;
  overflow: hidden;
}
.quiz .answer-text .choice .label {
  height: 20%;
  padding-top: 45%;
  padding-bottom: 40%;
  overflow: hidden;
  color: #fff;
}
.tab-key .block6 [role="button"]:focus .answer-image,
.tab-key .block12 [role="button"]:focus .answer-image {
  border: 1px solid #767676;
  width: calc(100% - 2px);
}
.tab-key .answer-grid-compact .choice:focus,
.tab-key .answer-grid .choice:focus {
  box-shadow: none;
  border: 1px solid #767676;
}
.flash-alert .modal-container {
  font-family: NeueEinstellungRegular;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.flash-alert .modal-container.open {
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  opacity: 1;
  visibility: visible;
  -webkit-transition: -webkit-transform 0.25s ease-in-out,
    opacity 0.25s ease-in-out;
  -moz-transition: -moz-transform 0.25s ease-in-out, opacity 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out;
}
.flash-alert .modal-container .pink {
  color: #ef447f;
}
.flash-alert .balancer {
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 100%;
}
.flash-alert .inner {
  display: inline-block;
  vertical-align: middle;
  width: calc(99% - 62px);
  max-width: 480px;
  margin: 20px 30px 0;
  -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
}
.splash-quiz.wrapper {
  max-width: none;
}
.splash-quiz .container {
  padding: 0;
}
.splash-quiz .splash-quiz-title {
  margin-top: 30px;
  margin-bottom: 20px;
}
.splash-quiz .inside-quiz-title {
  margin-top: 20px;
  margin-bottom: 0;
}
.splash-quiz .quiz-questions {
  margin: 10px auto 30px;
}
.splash-quiz .question {
  padding: 20px 0;
}
.splash-quiz .quiz .toolbar-control {
  margin: 0 auto;
}
@media only screen and (min-width: 375px) {
  .quiz .answer-swatch .answer-label {
    font-size: 12px;
  }
  .quiz .answer-wrap.stack .choice {
    padding: 10px 0;
  }
  .quiz .answer-wrap.stack .choice .label {
    margin-left: 90px;
  }
  .quiz .answer-wrap.stack .choice .answer-image {
    top: 0;
    left: 18px;
    width: 75px;
  }
  .quiz .answer-wrap.stack.cards .choice .answer-image {
    padding: 0;
  }
  .quiz .answer-wrap.stack.cards .choice .answer-container .check-mark {
    top: auto;
  }
}
@media only screen and (min-width: 414px) {
  .quiz .answer-wrap.stack .choice {
    padding: 20px 0;
  }
  .quiz .answer-wrap.stack .choice .label {
    margin-left: 100px;
  }
  .quiz .answer-wrap.stack.cards .choice .answer-image {
    width: 85px;
  }
}
@media only screen and (orientation: portrait) and (min-width: 480px) {
  .quiz .question {
    padding: 0 0 30px;
  }
  .quiz .answer-wrap {
    width: 100%;
    margin: 10px 0;
  }
  .quiz .block8 .choice,
  .quiz .block12 .choice {
    width: 33.334%;
  }
  .quiz .answer-grid-compact .choice,
  .quiz .answer-grid .choice {
    width: 32.9%;
  }
  .quiz .slide-indicator {
    padding: 0;
    margin: 30px 0;
  }
  .quiz .slide-indicator li:hover {
    cursor: default;
  }
}
@media only screen and (max-width: 480px) {
  .quiz .terms-checkbox {
    height: 70px;
  }
}
@media only screen and (min-width: 480px) {
  .flash-alert .inner {
    margin-top: 60px;
  }
  .quiz .answer-container .cbb-check {
    font-size: 30px;
    top: calc(50% - 26px);
    left: calc(50% - 15px);
  }
  .quiz .answer-swatch {
    width: 420px;
  }
  .quiz .answer-swatch .swatch {
    width: 110px;
    height: 110px;
    -webkit-border-radius: 55px;
    -moz-border-radius: 55px;
    border-radius: 55px;
  }
  .quiz .answer-swatch .label {
    width: 150px;
    margin: 0 auto;
  }
  .quiz .answer-swatch .choice {
    margin: 0 15px 20px;
  }
  .quiz .answer-wrap .choice .label {
    padding-bottom: 10%;
  }
}
@media only screen and (min-width: 480px) and (max-width: 622px) and (max-height: 479px) {
  .quiz .answer-container .cbb-check {
    font-size: 24px;
    top: calc(50% - 20px);
    left: calc(50% - 15px);
  }
}
@media only screen and (min-width: 623px) and (max-width: 767px) and (max-height: 766px) {
  .quiz .answer-container .cbb-check {
    font-size: 30px;
    top: calc(50% - 22px);
    left: calc(50% - 17px);
  }
}
@media only screen and (min-width: 768px) {
  .flash-alert .inner {
    margin-top: 100px;
  }
  .quiz .question {
    padding: 0 0 40px;
  }
  .quiz .quiz-questions .toolbar-control {
    padding-top: 60px;
  }
  .quiz .quiz-questions .toolbar-control .back,
  .quiz .quiz-questions .toolbar-control .forward {
    width: auto;
  }
  .quiz .slide-indicator {
    padding: 0;
    margin: 60px 0 40px;
  }
  .quiz .answer-grid-compact .choice,
  .quiz .answer-grid .choice {
    width: calc(25% - 3px);
  }
  .quiz .block6 .choice {
    width: 32.83%;
    margin: 0.25%;
  }
  .quiz .block8 .choice,
  .quiz .block12 .choice {
    width: 24.75%;
  }
  .quiz .answer-swatch {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
  }
  .quiz .answer-swatch .swatch {
    width: 100px;
    height: 100px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
    margin: 10px 20px;
  }
  .quiz .answer-swatch .choice {
    margin: 0 6px 20px;
  }
  .quiz .answer-swatch .choice .label {
    width: 140px;
    font-size: 12px;
  }
  .quiz .answer-wrap.block6 .choice {
    border: none;
  }
  .quiz .answer-wrap.stack {
    width: 80%;
    margin: 0 auto;
  }
  .quiz .answer-wrap.stack .choice {
    padding: 0;
    width: 33.3%;
    height: 400px;
    box-sizing: border-box;
  }
  .quiz .answer-wrap.stack .choice .label {
    padding: 160px 40px 20px;
    margin-left: 0;
    text-align: center;
  }
  .quiz .answer-wrap.stack .choice .label h1 {
    margin: 0 0 10px;
  }
  .quiz .answer-wrap.stack.cards .choice {
    border: 1px solid #ddd;
    height: 320px;
    padding: 30px 0 60px;
    margin-right: 7px;
    margin-bottom: 7px;
    width: calc(33.3% - 7px);
  }
  .quiz .answer-wrap.stack.cards .choice .label {
    padding: 30px 10px 20px;
  }
  .quiz .answer-wrap.stack.cards .choice .answer-image {
    float: none;
    width: 90px;
    left: 0;
    margin: 0 auto;
  }
  .quiz .answer-wrap.stack.cards .choice .answer-container .check-mark {
    top: -15px;
  }
  .quiz .answer-radio {
    width: 60%;
    margin: 0 auto;
  }
  .quiz .answer-frequency .answer-frequency-thumbnail-wrapper {
    width: 35%;
  }
  .quiz .answer-frequency .choice-wrapper {
    padding-left: 20px;
    padding-right: 20px;
    align-items: center;
    flex-direction: row;
  }
  .quiz .answer-grid .choice .answer-image {
    padding: 35px 0;
  }
  .splash-quiz .splash-quiz-title {
    margin-top: 60px;
    margin-bottom: 50px;
  }
  .splash-quiz .inside-quiz-title {
    margin-top: 0;
    margin-bottom: 30px;
  }
  .splash-quiz .carousel {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
@media only screen and (min-width: 1024px) {
  .quiz .choice {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
  }
  .quiz .block8,
  .quiz .block12 {
    width: 94%;
    margin: 0 3%;
  }
  .quiz .block6 {
    width: 69%;
    margin: 0 15%;
  }
  .quiz .block12 .choice {
    width: 16.567%;
    margin: 0.05%;
  }
  .quiz .block8 .choice {
    width: 24.9%;
    margin: 0.05%;
  }
  .quiz .answer-grid-compact .choice,
  .quiz .answer-grid .choice {
    width: calc(16.7% - 3px);
  }
  .quiz .answer-grid .choice .answer-image {
    padding: 32px 0;
  }
  .quiz .subquestion-wrapper .answer-inner-wrapper .answer-label {
    font-size: 12px;
  }
  .quiz .answer-wrap.stack .choice {
    height: 450px;
  }
  .quiz .answer-wrap.stack .choice .label {
    padding: 180px 80px 20px;
  }
  .quiz .answer-wrap.stack.cards .choice .answer-image {
    width: 110px;
    margin-top: 5px;
  }
  .quiz .answer-wrap.stack.cards .choice .answer-container .check-mark {
    top: -5px;
    right: 25px;
  }
  .quiz .answer-swatch {
    width: 100%;
  }
  .quiz .answer-swatch .choice {
    float: none;
    margin: 0;
  }
  .quiz .answer-radio {
    width: 45%;
  }
}
@media only screen and (min-width: 1280px) {
  .quiz .answer-grid .choice .answer-image {
    height: 55px;
  }
  .quiz .answer-grid-compact .choice,
  .quiz .answer-grid .choice {
    width: 12.33%;
  }
  .quiz .answer-grid-compact .choice .answer-image,
  .quiz .answer-grid .choice .answer-image {
    width: auto;
    max-width: 100%;
  }
}
@media only screen and (min-width: 1600px) {
  .quiz-take {
    background-color: #ddd;
  }
  .wrapper {
    background-color: #fff;
  }
  .quiz {
    padding-bottom: 30px;
  }
}

/* Color Swatches */
.skin-1,
.swipe3.fair {
  background-color: #f9e5d9 !important;
}
.skin-2,
.swipe3.light {
  background-color: #efd2cc !important;
}
.skin-3,
.swipe3.medium {
  background-color: #deab7d !important;
}
.skin-4,
.swipe3.tan {
  background-color: #b67245 !important;
}
.skin-5,
.swipe3.dark {
  background-color: #64321e !important;
}
.skin-6,
.swipe3.deep {
  background-color: #452c28 !important;
}
.eye-1,
.swipe1.brown {
  background-color: #5a2209 !important;
}
.eye-2,
.swipe1.hazel {
  background-color: #a77144 !important;
}
.eye-3,
.swipe1.blue {
  background-color: #5e7393 !important;
}
.eye-4,
.swipe1.green {
  background-color: #6e844f !important;
}
.eye-5,
.swipe1.amber {
  background-color: #994812 !important;
}
.eye-6,
.swipe1.gray {
  background-color: #818088 !important;
}
.eye-7,
.swipe1.violet {
  background-color: #635a95 !important;
}
.eye-8,
.swipe1.other {
  background-color: #c8b22d !important;
}
.hair-1,
.swipe2.black {
  background-color: #0f0d0e !important;
}
.hair-2,
.swipe2.dark {
  background-color: #361911 !important;
}
.hair-3,
.swipe2.light {
  background-color: #7c5535 !important;
}
.hair-4,
.swipe2.blonde {
  background-color: #d7b983 !important;
}
.hair-5,
.swipe2.red {
  background-color: #843b3a !important;
}
.hair-6,
.swipe2.gray {
  background-color: #8b8b8b !important;
}
.hair-7,
.swipe2.white {
  background-color: #e2e2e2 !important;
}
.hair-8,
.swipe2.other {
  background-color: #875a96 !important;
}

.skin-type-1 {
  background-image: url("/img/skins/Skin-Dry.webp");
  background-repeat: no-repeat;
  background-position: center;
}
.skin-type-2 {
  background-image: url("/img/skins/Skin-Oily.webp");
  background-repeat: no-repeat;
  background-position: center;
}
.skin-type-3 {
  background-image: url("/img/skins/Skin-Combination.webp");
  background-repeat: no-repeat;
  background-position: center;
}

/* Radio Options */
.quiz-radio-options {
  position: relative;
  margin: 0 auto;
  width: 100%;
  text-align: start;
}

.quiz-radio-option {
  cursor: pointer;
  margin-bottom: 2rem;
  border-bottom: 1px solid #ddd;
}

.quiz-radio-option:last-child {
  border: none;
}

@media only screen and (min-width: 768px) {
  .quiz-radio-options {
    width: 60%;
  }
}

@media only screen and (min-width: 1024px) {
  .quiz-radio-options {
    width: 40%;
  }
}
