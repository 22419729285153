.status-bar {
  align-content: stretch;
  background: #e4e4e4;
  border-radius: 5px;
  display: flex;
  height: 15px;
  margin-bottom: 3em;
  position: relative;
  width: 100%;
}
.status-bar::after {
  background: #fff;
  border-radius: 50%;
  content: "";
  display: none;
  height: 30px;
  position: absolute;
  right: -5px;
  top: -8px;
  width: 30px;
}
.status-bar.is-exception {
  background: linear-gradient(90deg, #2f4366, #db2b35);
}
.status-bar.is-exception::after {
  border: 6px solid #db2b35;
  display: block;
}
.status-bar.is-exception .status-bar__state {
  display: none;
}
.status-bar.is-exception .is-warning {
  display: block;
  position: static;
}
.status-bar .is-warning .status-bar__title {
  color: #db2b35;
  display: block;
  right: 0;
  text-align: right;
}
.status-bar.is-delivered {
  background: #43a047;
}
.status-bar.is-delivered::after {
  border: 6px solid #43a047;
  display: block;
}
.status-bar.is-delivered .is-active {
  background: transparent;
}
.status-bar.is-delivered .is-active::after {
  display: none;
}
.status-bar.is-delivered .status-bar__state--created .status-bar__title,
.status-bar.is-delivered .status-bar__state--created::after {
  display: none;
}
.status-bar.is-delivered .status-bar__state:last-child .status-bar__title {
  display: block;
}
.status-bar__state {
  flex: 1 1 auto;
  height: 15px;
  position: relative;
}
@media (max-width: 575.98px) {
  .status-bar__state {
    padding: 10px 0;
  }
}
.status-bar__state:first-child {
  border-radius: 5px 0 0 5px;
}
.status-bar__state:last-child {
  border-radius: 0 5px 5px 0;
}
.status-bar__state::after {
  background: #fff;
  border-radius: 50%;
  content: "";
  display: none;
  height: 30px;
  position: absolute;
  top: -8px;
  width: 30px;
}
.status-bar__state--created::after {
  border: 6px solid #e4e4e4;
  display: block;
  left: 0;
}
.status-bar__state--created.is-active::after {
  display: none;
}
.status-bar__state--created .status-bar__title {
  display: block;
  left: 0;
  right: auto;
}
.status-bar__state--created.is-active .status-bar__title {
  display: none;
}
.status-bar__state.is-active {
  background: #2f4366;
}
@media (max-width: 575.98px) {
  .status-bar__state--received .status-bar__title {
    left: -3em;
    right: auto;
  }
}
.status-bar__state.last-active::after {
  border: 6px solid #2f4366;
  display: block;
  right: -15px;
}
.status-bar__state.last-active .status-bar__title {
  display: block;
  text-align: right;
}
.status-bar__title {
  display: none;
  font-weight: 700;
  padding-top: 0.5em;
  position: absolute;
  right: 20px;
  top: 100%;
  width: 12em;
}
.status-bar--small {
  height: 6px;
  margin-bottom: 1.5rem;
  width: 14em;
}
.status-bar--small .status-bar__state {
  height: 6px;
  padding: 0;
}
.status-bar--small .status-bar__state.last-active::after,
.status-bar--small .status-bar__state::after,
.status-bar--small.status-bar::after {
  border-width: 2px;
  height: 15px;
  right: -2px;
  top: -5px;
  width: 15px;
}

.va-top {
  vertical-align: text-top;
}

.tracking-status-box--within {
  background: #0875e2;
}

.tracking-status-box {
  align-items: center;
  background: #0875e2;
  margin-bottom: 1rem;
  padding: 1rem;
  color: #fff;
}
