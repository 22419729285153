/*------------------------------------
    Form Quantity Counter
  ------------------------------------*/
.input-group-quantity-counter {
  position: relative;
  width: 8rem;
}

.input-group-quantity-counter input[readonly] {
  background-color: #fff;
}

.input-group-quantity-counter input[readonly]:focus {
  border-color: #e5e5e5;
}

.input-group-quantity-counter .input-group-quantity-counter-control {
  padding-right: 4.0625rem;
}

.input-group-quantity-counter .input-group-quantity-counter-toggle {
  position: absolute;
  top: 50%;
  right: 0.5rem;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.input-group-quantity-counter .input-group-quantity-counter-btn {
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  color: #677788;
  font-size: 0.75rem;
  width: 1.53125rem;
  height: 1.53125rem;
  background-color: #fff;
  border: 0.0625rem solid #e7eaf3;
  border-radius: 50%;
}

.input-group-quantity-counter .input-group-quantity-counter-btn:hover {
  color: #fc90bd;
  box-shadow: 0px 3px 6px -2px rgba(140, 152, 164, 0.25);
}
