/*Profile*/
.profile-progress {
  width: 110px;
  height: 110px;
  background: none;
  position: relative;
}

.profile-progress::after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 6px solid #eee;
  position: absolute;
  top: 0;
  left: 0;
}

.profile-progress > span {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 1;
}

.profile-progress .profile-progress-left {
  left: 0;
}

.profile-progress .profile-progress-bar {
  width: 100%;
  height: 100%;
  background: none;
  border-width: 6px;
  border-style: solid;
  position: absolute;
  top: 0;
}

.profile-progress .profile-progress-left .profile-progress-bar {
  left: 100%;
  border-top-right-radius: 80px;
  border-bottom-right-radius: 80px;
  border-left: 0;
  -webkit-transform-origin: center left;
  transform-origin: center left;
}

.profile-progress .profile-progress-right {
  right: 0;
}

.profile-progress .profile-progress-right .profile-progress-bar {
  left: -100%;
  border-top-left-radius: 80px;
  border-bottom-left-radius: 80px;
  border-right: 0;
  -webkit-transform-origin: center right;
  transform-origin: center right;
}

.profile-progress .profile-progress-value {
  position: absolute;
  top: 0;
  left: 0;
}
