.cursor.cursor-copy {
  cursor: copy;
}

.cursor.cursor-crosshair {
  cursor: crosshair;
}

.cursor.cursor-default {
  cursor: default;
}

.cursor.cursor-e-resize {
  cursor: e-resize;
}

.cursor.cursor-ew-resize {
  cursor: ew-resize;
}

.cursor.cursor-grab {
  cursor: grab;
}

.cursor.cursor-grabbing {
  cursor: grabbing;
}

.cursor.cursor-help {
  cursor: help;
}

.cursor.cursor-move {
  cursor: move;
}

.cursor.cursor-n-resize {
  cursor: n-resize;
}

.cursor.cursor-ne-resize {
  cursor: ne-resize;
}

.cursor.cursor-nesw-resize {
  cursor: nesw-resize;
}

.cursor.cursor-ns-resize {
  cursor: ns-resize;
}

.cursor.cursor-nw-resize {
  cursor: nw-resize;
}

.cursor.cursor-nwse-resize {
  cursor: nwse-resize;
}

.cursor.cursor-no-drop {
  cursor: no-drop;
}

.cursor.cursor-none {
  cursor: none;
}

.cursor.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor.cursor-pointer {
  cursor: pointer;
}

.cursor.cursor-progress {
  cursor: progress;
}

.cursor.cursor-row-resize {
  cursor: row-resize;
}

.cursor.cursor-s-resize {
  cursor: s-resize;
}

.cursor.cursor-se-resize {
  cursor: se-resize;
}

.cursor.cursor-sw-resize {
  cursor: sw-resize;
}

.cursor.cursor-text {
  cursor: text;
}

.cursor.cursor-w-resize {
  cursor: w-resize;
}

.cursor.cursor-wait {
  cursor: wait;
}

.cursor.cursor-zoom-in {
  cursor: zoom-in;
}

.cursor.cursor-zoom-out {
  cursor: zoom-out;
}
