/*
  Full content overlay
*/
.overlay-default {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.5);
}

.overlay-gray {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  cursor: pointer;
  background: rgba(62, 62, 62, 0.5);
}

.overlay-gray-dark {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  cursor: pointer;
  background: rgba(43, 43, 43, 0.5);
}

.overlay-primary {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  cursor: pointer;
  background: rgba(252, 144, 189, 0.5);
}

.overlay-secondary {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  cursor: pointer;
  background: rgba(166, 166, 166, 0.5);
}

.overlay-success {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  cursor: pointer;
  background: rgba(40, 187, 116, 0.5);
}

.overlay-info {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  cursor: pointer;
  background: rgba(59, 134, 255, 0.5);
}

.overlay-warning {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  cursor: pointer;
  background: rgba(255, 151, 54, 0.5);
}

.overlay-danger {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  cursor: pointer;
  background: rgba(220, 53, 69, 0.5);
}

.overlay-light {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  cursor: pointer;
  background: rgba(245, 245, 245, 0.5);
}

.overlay-dark {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  cursor: pointer;
  background: rgba(31, 31, 31, 0.5);
}

/*
  Checkout Overlay
*/

.overlay-checkout {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  cursor: pointer;
  background: rgba(255, 255, 255, 255);
}

/*
  Product card overlays
*/

.overlay-unavailable {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  cursor: pointer;
  background: rgba(245, 245, 245, 0.5);
}
