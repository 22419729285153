/* The snackbar - position it at the bottom and in the middle of the screen */
.snackbar {
  min-width: 250px; /* Set a default minimum width */
  max-width: 350px;
  margin-left: -125px; /* Divide value of min-width by 2 */
  margin-right: 125px;
  background-color: #f8efef; /* White background color */
  color: #1f1f1f; /* dark text color */
  border-radius: 2px; /* Rounded borders */
  padding: 16px; /* Padding */
  position: fixed; /* Sit on top of the screen */
  text-align: center;
  z-index: 1070; /* Add a z-index if needed */
}

.snackbar-body {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* .snackbar.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 70px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 70px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 70px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    bottom: 70px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
} */
