.lds-ring {
  display: inline-block;
  position: relative;
  width: 5rem;
  height: 5rem;
  /* top: calc(50% - 5rem/2); */
  left: calc(50% - 5rem / 2);
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fc90bd;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fc90bd transparent transparent transparent;
}

.lds-ring.mini {
  width: 2rem !important;
  height: 2rem !important;
  right: calc(50% - 2rem / 2);
}

.lds-ring.mini div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  margin: 4px;
  border: 4px solid #fc90bd;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fc90bd transparent transparent transparent;
}

.lds-ring.lds-btn {
  width: 0.5rem !important;
  height: 0.5rem !important;
}

.lds-ring.lds-btn div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  margin: 4px;
  margin-top: -5px;
  border: 2px solid #ccc;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #ccc transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
